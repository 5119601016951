import {
  type ClientLoaderFunctionArgs,
  Navigate,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';

import {
  type DtoProduct,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { OnboardingPageLayout } from '../components/Onboarding/OnboardingLayout';
import { OTPUtils } from '../components/OneTimePurchase';
import { BookNowWidget } from '../components/Product/BookNow';
import { ProductUtils } from '../components/Product/utils';
import { apiService } from '../services/api-service';

function Container(props: { product: DtoProduct }) {
  const { product } = props;

  const navigate = useNavigate();

  const handleContinue = (_: DtoProduct, price: ModelsPrice) => {
    const params = new URLSearchParams(window.location.search);
    params.set('price-id', price.id);
    navigate(
      {
        pathname: '/onboarding/billing',
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
  };

  return (
    <OnboardingPageLayout progress={20}>
      <BookNowWidget
        onContinueBookingSubscription={handleContinue}
        subscriptionProduct={product}
        disabled={false}
        productDescriptionTitle={'Tell us about your team'}
        hideTalkToSomeone
      />
    </OnboardingPageLayout>
  );
}

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);

  const productId = url.searchParams.get('product-id');
  const priceId = url.searchParams.get('price-id');
  const headcount = OTPUtils.ParseHeadcount(url.searchParams.get('headcount'));

  const resp = await apiService.product.getPublicProducts();
  const product =
    resp.data.published.find((p) => p.id === productId) ||
    resp.data.published.find((p) => p.id === resp.data.defaultProductId);
  if (!product) {
    throw new Response('Not Found', { status: 404 });
  }
  const price = priceId
    ? product.prices?.find((p) => p.id === priceId)
    : headcount
    ? ProductUtils.FindPrice(product, headcount)
    : null;

  return { product, price, headcount };
}

export function Component() {
  const { product, price, headcount } = useLoaderData<typeof clientLoader>();

  // if price is already set, redirect to billing page
  if (!!price) {
    const params = new URLSearchParams(window.location.search);
    params.set('price-id', price.id);
    if (!headcount) {
      params.set('headcount', price.maxSeats.toString());
    }

    return (
      <Navigate
        to={{
          pathname: '/onboarding/billing',
          search: params.toString(),
        }}
        replace
      />
    );
  }

  return <Container product={product} />;
}
